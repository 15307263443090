
import { Divbackground } from '../../DesignSystem/style';
import { CaixaFundoMessage,Imagem, Message,Botao} from './Style';

import React, { useLayoutEffect, Component} from 'react';
import { useLocation } from 'react-router';


export default function Disapproved(values) {

  const location = useLocation();
  const data = location.state;

  useLayoutEffect(() => {

      }, []);

      function abreSiteMila() {
        return (
          window.open('https://www.milausa.com/')
        );
      }

    return (
      
       
<Divbackground>  
    <CaixaFundoMessage>     
    <Imagem>          
      <img src="../mila2.png"/>
   </Imagem> 
      <Message>
        {data.Message}
      </Message>
       
      <Botao type="submit" onClick={() => { abreSiteMila() }}  >
        Back
    </Botao>    

       
</CaixaFundoMessage>

</Divbackground>


    )
    
}