
import { Imagem,DivBotao,Botao,DivCampo,Span } from './Style';
import { Divbackground, CaixaFundo } from '../../DesignSystem/style';
import { validateYupSchema } from 'formik';
import { useHistory } from 'react-router-dom';
import React from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import schema from '../../schemas/schema'

export default function Login(values) {



 
  const router = useHistory();  

function onSubmit(values) {
   
    if (values.name !== '' || values.email !== '' || values.phone !== ''){

        router.push(
            '/Test',
            {
              name: values.name,
              email: values.email,
              phone: values.phone,
              id: 1
            });
    } 
}

    return (      
       
<Divbackground>  
<Formik
validationSchema={schema}
onSubmit={onSubmit}
initialValues = {{
    name: '',
    email: '',
    phone: '',
}}
render = {({values, errors}) => (


    <CaixaFundo>     
               
    <Imagem>          
      <img src="../mila2.png"/>
   </Imagem> 
<Form>
  <DivBotao id="btnStart">



   <DivCampo>                
      <Field  name="name"  type="text" required/>
       <label>Name</label>     
       {errors.name && (
           <Span>{errors.name}</Span>
       )}
          </DivCampo>

   <DivCampo>               
      <Field  name="email"  type="text" required />
      <label>Email</label>     
      {errors.email && (
           <Span>{errors.email}</Span>
       )}           
      </DivCampo>
  <DivCampo>               
      <Field  name="phone"  type="text" required  />
      <label>Phone</label>   
      {errors.phone && (
           <Span>{errors.phone}</Span>
       )}                        
      </DivCampo>                
    <Botao type="submit">
        Start
    </Botao>    
    </DivBotao>         
</Form>    
       
</CaixaFundo>


)}
/>
</Divbackground>
  
    )
    
}