import Lottie from "react-lottie";

import * as utils from "../../utilitario";

import erro from "../../assets/animated/error.json";
import aviso from "../../assets/animated/aviso.json";
import sucesso from "../../assets/animated/sucesso.json";
import carregando from "../../assets/animated/carregando.json";

const retornaSource = (tipo) => {
    const obj = {
      source: null,
    };
    if (tipo == utils.animacoes.sucesso) {
      obj.source = sucesso;
    } else if (tipo == utils.animacoes.aviso) {
      obj.source = aviso;
    } else if (tipo == utils.animacoes.erro) {
      obj.source = erro;
    } else if (tipo == utils.animacoes.carregando) {
      obj.source = carregando;
    } 
    return obj;
  };



  export const AnimacaoLottie = ({tipo,loop, finalizar}) => {

    const dadosSource = retornaSource(tipo);
  
      const defaultOptions = {
          loop: loop,
          autoplay: true,
          animationData: dadosSource.source,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            hideOnTransparent: true,
          },
        };
  
      return (<Lottie
                options={defaultOptions}
                
                height={"100%"}
                width={"100%"}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () => finalizar(),
                  },
                  //   {
                  //     eventName: 'loopComplete',
                  //     callback: () => alert('the animation loopComplete:')
                  //   }
                ]}
              />)
  };