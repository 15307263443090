
import React, { useEffect } from 'react';
import { Paragrafo,Questao } from './alinhamento';


export function Perguntas ({Tipo ,Numero, Descricao}) {

    if (Tipo==0){   
      
    return (
        <div style= {{color:'black'}}> 
          <Questao>{Descricao}</Questao>       
        </div>        
           )
    }
    

    if (Tipo==1 ) {
     
  

    return (
        <div style= {{color:'black'}}> 
        <Questao> {Numero}.   {Descricao} </Questao>
        </div>        
           )
    }


      
    if (Tipo==2 ) {
    
      return (
        
          <div style= {{color:'black'}}> 
          <Questao> {Numero}. </Questao>
          </div>        
             )
      }






}

