import React from 'react';

import { Titulo } from './alinhamento';

export  function Level({Level}) {
    
    return (        
           <Titulo> Level {Level} </Titulo> 
        )
}

