import axios from 'axios';
import * as utils from '../utilitario';

const base = "http://www.excelenciasolucoes.net.br/Demo/milatest/";

const api = axios.create({
    baseURL: base,
});




export  const salvarTest = async ({dados}) => {
    try {
        
        if (!dados) throw new Error("Dados não enviado")
        const response = await api.post(`api/Test/Save`, dados);
  
  
        return response.data;
    } catch (e) {
  

        const erro = utils.trataErro(e, "salvarTest");
        
   
      throw erro;     
  
    }
  }


  export const FinishTestAluno = async ({dados}) => {
  
    try { 
    
      const retorno = await api.post(`api/Test/Finish`, dados);
      return retorno.data;
    } catch (e) {

        const erro = utils.trataErro(e, "FinishTestAluno");
        
   
      throw erro;     
  
    }
  }

  