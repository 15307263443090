import styled from 'styled-components';
import {Field } from 'formik';






export const DivRadioType2 =styled.div`

   
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;  
    display: table-cell;
    top: 50px;
`;



export const DivRadio =styled.div`

   
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    display: flex;
    vertical-align: 0px;

`;

export const RadioField  =styled(Field)`
-webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  margin-right: 0.5rem;
  outline: none;
  position: flex;
  border-radius: 50%;
 
  &:hover {
    background: #9faab7;
  }
  &:checked {
    background: #ff7505;
  }
  &:checked::before {
    height: 20px;
    width: 20px;
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: 16.66667px;
    text-align: center;
    line-height: 20px;
  }
 &:checked::after {
  -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #40e0d0;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
  }
`;








export const SpanRadioInput  =styled.span`

`;