

export const macDesktop = window.navigator.userAgent.indexOf("Macintosh") > -1;


//#region  ALERTAS

export const animacoes = {
    carregando: 1,
    aviso: 2,
    erro: 3,
    sucesso: 4,
    dashboard: 5,
    assinaturaTermo: 6,
    uploadTermo: 7,
    uploadCNH: 8,
    indicacaoExpirada: 9,
    emConstrucao: 10,
  };
  export const propsAlerta = {
    titulo: "",
    subTitulo: "",
    origem: "",
    tipoAnimacao: 0,
    usuarioFechar: false,
    exibir: false,
    loop: true,
  };
  
  export const semAlerta = Object.assign({}, propsAlerta);
  
  export const alertaCarregando = ({exibir}) => {
    const obj = Object.assign({}, propsAlerta);
    obj.tipoAnimacao = animacoes.carregando;
    obj.usuarioFechar = false;
    obj.exibir = exibir;
    return obj;
  };
  export const alertaErro = ({ origem, titulo, subTitulo }) => {
    const obj = Object.assign({}, propsAlerta);
    obj.titulo = titulo;
    obj.subTitulo = subTitulo;
    obj.origem = origem;
    obj.tipoAnimacao = animacoes.erro;
    obj.usuarioFechar = true;
    obj.exibir = true;
    return obj;
  };
  
  export const alertaAviso = ({ origem, titulo, subTitulo }) => {
    const obj = Object.assign({}, propsAlerta);
    obj.titulo = titulo;
    obj.subTitulo = subTitulo;
    obj.origem = origem;
    obj.tipoAnimacao = animacoes.aviso;
    obj.usuarioFechar = true;
    obj.exibir = true;
    return obj;
  };
  
  export const alertaSucesso = ({ titulo, subTitulo }) => {
    const obj = Object.assign({}, propsAlerta);
    obj.titulo = titulo;
    obj.subTitulo = subTitulo;
    obj.tipoAnimacao = animacoes.sucesso;
    obj.usuarioFechar = false;
    obj.loop = false;
    obj.exibir = true;
    return obj;
  };
  
  export const montaAlerta = ({ tAnimacao, origem, titulo, subTitulo }) => {
    const obj = Object.assign({}, propsAlerta);
    obj.titulo = titulo;
    obj.subTitulo = subTitulo;
    obj.origem = origem;
    obj.tipoAnimacao = tAnimacao ?? animacoes.carregando;
    obj.usuarioFechar =
      obj.tipoAnimacao == animacoes.erro || obj.tipoAnimacao == animacoes.aviso;
    obj.exibir = true;
    return obj;
  };
//#endregion




export const trataErro = (ex, origem) => {
    try {
      let erro = ex;
      erro.message = retornaMsgErro(ex);

      if (!erro?.tipoAlerta) {
        erro.tipoAlerta = animacoes.erro;
        if (ex?.response?.data?.CodigoRetorno == 2) erro.tipoAlerta = animacoes.aviso;
      }
  
      erro.origem = montaOrigem(ex, origem);
   
      return erro;
     
    } catch (e) {

      return ex;
    }
  };


  const retornaMsgErro = (ex) => {
    let ret = "";
  
    if (ex?.response?.data?.DescricaoRetorno) ret = ex?.response?.data?.DescricaoRetorno;
 
    if (!ret) {
      ret = ex.message;
    }
  
    if (ret.toLocaleLowerCase() == "network error") {
      ret = "No internet connection.";
    }
  
    return ret;
  }

  export const montaOrigem = (ex, origem) => {
    try {
      let erro = ex;
  
      erro.origem = "".concat(ex?.origem, ex?.origem && " - ", origem);
  
      return erro;
    } catch (e) {
      return ex;
    }
  };