import styled from 'styled-components'






export const DivBotao = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
row-gap:10px;
`;

export const DivCampo = styled.div`
 position: relative;
 width: 350px;
 input {
    width: 100%;
    padding: 16px;
    font-size: 18px;
    background:  transparent;
    color: black;    
    border: none;
    outline: none;
    border-bottom: 1px solid black; 
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    
 }

  label{
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px 0;
        font-size: 16px;
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        pointer-events: none;
        transition: .5s;
    }

    input:focus~label,
     input:valid~label
    {
       top: -20px;
        left:  0;
       font-size: 12px;
   }
`;




export const Botao = styled.button `
width: 100%;
height: 40px;
border: none;
outline: none;
color:black;
background: linear-gradient(to right, #ff7505, #ff7505);  
padding: 10px 20px;
font-size: 18px;
cursor: pointer;
border-radius: 15px;
margin-top:60px;
box-shadow: 0 3px 0 rgb(77, 75, 75);


:hover {
    background: linear-gradient(to right, #ff7605d7, #ff7605d7); 
    color:black;
}

:active {
    position:relative;
    top:5px;
    box-shadow: none;
}




`;



export const Imagem = styled.image `

height: 200px;
width: 120px;
left: 0;
right: 0;
top: -40px;
display: flex;
align-items: center;
justify-content: center;

img{
    width: 140%;
    
}


`;






export const Span = styled.span `
 width: 100%;
 color:red;
 font-style:italic;
 font-weight:480;
 display: flex;
 border-left: 15px solid transparent;
 border-right: 15px solid transparent;
`;