import styled from 'styled-components'



export const Divbackground = styled.div `
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
`;



export const CaixaFundo = styled.div `
background-color:  rgba(255, 255, 255, 0.860);
width: 720px;
border-radius: 50px 10px 50px 10px;
color: rgba(255, 255, 255, 0.860);
position: relative;  
height: 95vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
row-gap: 20px;

form {
    display:flex;
}
@media (min-width: 1450px) {
               
                justify-content: center;
            }
`;









export const CaixaFundoQuestion = styled.div `
background-color:  rgba(255, 255, 255, 0.860);
width: 720px;
border-radius: 50px 10px 50px 10px;
color: rgba(255, 255, 255, 0.860);
position: relative;  
height: 95vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
row-gap: 20px;
height: min-content;

form {
    display:flex;
}
@media (min-width: 1450px) {
               
                justify-content: center;
            }
`;
