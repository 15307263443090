import styled from 'styled-components'

const fontePadrao = { primaria: 'Montserrat', secundaria: 'Lato' }

export const Tema = {CorFundoApp: '#fdc629',CorFundoTela: '#e2e2e2', CorSecundaria: '#52658c', CorTerciaria: '#52658c'
, CorAdicionar: "#67b652"
, CorFont: "#575756", Font: fontePadrao.primaria, FontSecundaria: fontePadrao.secundaria , CorFundoCampos:  'rgba(130, 130, 130, 0.10)'
, corSucesso: "green", corErro: "#9F1600", corAviso: "#F0952A"
, fundoCarregando:{
    backgroundColor: '#F1F1F1',top:('25%'),marginLeft: ('5.5%'), marginRight: ('5.5%'), height: "90%"
}
, fundoProcessando:{
    backgroundColor: '#FFFF',top:"9%",marginLeft: ('5.5%'), marginRight: ('5.5%'), height: "82%"
}
};

export const DivMensagem =styled.div`
text-align:center;
align-items:center;
margin: 8%;
`;


export const DivBotao =styled.div`

    align-items: center;
    justify-content: right;
    margin: 13%;


`;


export const TamanhoModal =styled.div`
text-align:center;

`;

const TextoPadrao =styled.span`
font-Family: ${Tema.Font};
color: ${Tema.CorFont};
`;

export const TextoAnimacao =styled(TextoPadrao)`
color: black;
text-align: center;
font-Size: 1.2em;
text-transform: uppercase;
`;






export const BotaoSeguir  = styled.a`
width: 18%;
height: 40px;
border: none;
outline: none;
color:black;
background: linear-gradient(to right, #ff7505, #ff7505); 
border-radius: 15px;
margin-top:60px;
box-shadow: 0 3px 0 rgb(77, 75, 75);
align-items:center;


    background-color: #ff7505;
    color: white;
    font-family: Lato;
    font-weight: 900;
    font-size: 25px;
    border: 0;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
    background: linear-gradient(to right, #ff7605d7, #ff7605d7); 
    color:white;
}

:active {
    position:relative;
    top:5px;
    box-shadow: none;
}
`;
    
