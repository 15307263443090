import styled from 'styled-components'



export const CaixaFundoMessage = styled.div `
background-color:  rgba(255, 255, 255, 0.860);
width: 720px;
border-radius: 50px 10px 50px 10px;
color: rgba(255, 255, 255, 0.860);
position: absolute;  
height: 95vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
row-gap: 20px;

form {
    display:absolute;
}
@media (min-width: 1450px) {
               
                justify-content: center;
            }
`;



export const Imagem = styled.image `

height: 200px;
width: 120px;
left: 0;
right: 0;
top: -40px;
display: flex;
align-items: center;
justify-content: center;

img{
    width: 140%;
    
}


`;


export const Message = styled.h1 `
color: black;
left: 0;
right: 0;
top: -70px;
display: absolute;
align-items: center;
justify-content: center;
text-align:center;
font-style: italic;
color:black;
font-weight:700;
font-family:'Lato', Helvetica, sans-serif;

`;




export const Botao = styled.button `
width: 18%;
height: 40px;
border: none;
outline: none;
color:black;
background: linear-gradient(to right, #ff7505, #ff7505);  
padding: 10px 20px;
font-size: 18px;
cursor: pointer;
border-radius: 15px;
margin-top:60px;
box-shadow: 0 3px 0 rgb(77, 75, 75);
align-items:center;
:hover {
    background: linear-gradient(to right, #ff7605d7, #ff7605d7); 
    color:black;
}

:active {
    display: absolute;
    position:relative;
    top:5px;
    box-shadow: none;
}
`;