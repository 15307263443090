import Modal from "../modal/modal";

import * as Tema from '../alinhamento';
import * as utils from "../../utilitario";
import { AnimacaoLottie } from "./lottie";







const retornaDadosLoading = (tipo) => {
    const obj = {
      cor: "",
      texto: "",
    };
    obj.cor = "var(--color-cliente)";
    obj.cor = "white";
    
    
    if (tipo == utils.animacoes.sucesso) {
      obj.cor = Tema.Tema.corSucesso;
    } else if (tipo == utils.animacoes.aviso) {
      obj.cor = Tema.Tema.corAviso;
    } else if (tipo == utils.animacoes.erro) {
      obj.cor = Tema.Tema.corErro;
    } 
    return obj;
  };
  
  export const Carregando = ({
    tipo,
    onFinish,
    enviandoTexto,
    exibir,
    setExibir,
    origem,
    titulo,
    subTItulo,
    userClose,
    styleFundo,
    styleAnimacao,
    styleTexto,
    children,
    ...props
  }) => {
    const dadosLoading = retornaDadosLoading(tipo);
    dadosLoading.texto = titulo ? titulo : "Loading...";
  
    const finalizar = () => {
      if (onFinish) onFinish();
    };
  
    const fechar = () => {
      if (setExibir) setExibir();
      if (onFinish) onFinish();
    };
  
  
  
    if (origem) console.log(origem);
  
    return (
      <Modal
        abrir={exibir}
        fecharClicarFora={false}
        onFechar={() => {
          fechar();
        }}
      >
        <Tema.CarregandoFundo style={styleFundo}>
          {userClose ? (
            <Tema.DivFechar>
              <Tema.TextoAnimacao
                style={{
                  backgroundColor: "#9F1600",
                  color: Tema.Tema.CorFundoTela,
                  padding: 10,
                  borderRadius: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  fechar();
                }}
              >
                CLOSE
              </Tema.TextoAnimacao>
            </Tema.DivFechar>
          ) : (
            <div />
          )}
          <div
            id="modal"
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Tema.AnimacaoFundo style={styleAnimacao}>
              <AnimacaoLottie tipo ={tipo} finalizar ={finalizar} loop ={props.loop} />
              {/* <Lottie
                options={defaultOptions}
                height={"100%"}
                width={"100%"}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () => finalizar(),
                  },
                  //   {
                  //     eventName: 'loopComplete',
                  //     callback: () => alert('the animation loopComplete:')
                  //   }
                ]}
              /> */}
            </Tema.AnimacaoFundo>
            <div id="animacaoTexto" style={{ width: "100%" }}>
              {children ? (
                children
              ) : (
                <Tema.TextoAnimacaoFundo style={styleTexto}>
                  <Tema.TituloAnimacao corTexto={tipo && dadosLoading.cor}>
                    {dadosLoading.texto}
                  </Tema.TituloAnimacao>
                  <Tema.TextoAnimacao>{subTItulo}</Tema.TextoAnimacao>
                </Tema.TextoAnimacaoFundo>
              )}
            </div>
          </div>
        </Tema.CarregandoFundo>
      </Modal>
    );
  };
  