
import { Divbackground, CaixaFundoQuestion } from '../../DesignSystem/style';
import React, { useState , useLayoutEffect,useEffect} from 'react';
import { Level } from '../../Components/Level.js.js';
import { Perguntas } from '../../Components/Question';
import {Formik, FieldArray} from 'formik';
import * as utils from '../../utilitario'
import Respostas from '../../Components/Answer';
import { useLocation } from 'react-router';
import { FinishTest, salvar } from '../../API/consulta';
import { Button,DivButton } from '../../Components/button';
import { ImagemMila } from '../../Components/alinhamento';
import { useHistory } from 'react-router-dom';
import { CaixaFundoMessage,Imagem, Message, Botao } from '../Disapproved/Style';
import Modal from 'react-modal';
import Mensagem from '../Message/Index';
import { Carregando } from '../../Components/loading';

export default function Test() {



const location = useLocation();
const data = location.state;
const [obj, setObj] = useState({});
const [exibeMensagem, setMensagem] = React.useState(false);
const [countQuestion, setCountQuestion] = React.useState(0);
const [exibirModal, setExibirModal] = React.useState(false);
const [carregando, setCarregando] = useState(0);

const stilo = {
  overlay: {
    backgroundColor: 'transparent'
    },
  content: {
  backgroundColor: 'white',
  inset: 0,
  overflow: 'hidden',
  width: '30%',
  height: '30%',
  display: 'grid',
  margin: 'auto',
  borderWidth: '0',
  position: 'absolute',
  padding: '0', 
  borderRadius: '50px 10px 50px 10px',
  boxShadow: '0 10px 20px',
  left:'0', top: '0', bottom: '0', right:0
  }
  };
const router = useHistory();  






const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);
useLayoutEffect(() => {


    SalvarConsultarQuestion(data.name, data.email, data.phone, data.id);     
    
    

      }, []);

const SalvarConsultarQuestion = async(name,email,phone,id) => {    

    
    try {       


      setPropsAlerta(
        utils.alertaCarregando({      
          exibir:true,
        
        })
      );
           const ret = await salvar({name, email, phone, id})     
             sessionStorage.setItem('idLevel', ret.data.Id)
           sessionStorage.setItem('idTest', ret.data.IdTest)
          setObj(ret.data)     

          setPropsAlerta(
            utils.alertaCarregando({      
              exibir:false,
            
            })
          );



    } catch (e){
    
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );

    }




}    

function nextTest() {
  setMensagem(false)
const listresposta = {listAnswer: []}
setObj(listresposta)

const ret = obj;
setObj(ret)
}




const FinalizarTeste = async(values,resetForm) => {    
   
  try {       

    setPropsAlerta(
      utils.alertaCarregando({      
        exibir:true,
      
      })
    );

    var idLevel = sessionStorage.getItem('idLevel');      
    var idTest = sessionStorage.getItem('idTest');      

  const ret = await FinishTest(values,idLevel, idTest) 
  sessionStorage.setItem('idLevel', ret.data.Id)
  sessionStorage.setItem('idTest', ret.data.IdTest)

      if(ret.data.Result.oEnum ==0){
        router.push(
          '/Finish',
          {
            Message: ret.data.Message          
          });
         
          setObj(ret.data)
        
    }else{
      setMensagem(true)
     resetForm();
      setObj(ret.data) 
  
    }

    setPropsAlerta(
      utils.alertaCarregando({      
        exibir:false,
      
      })
    );




  }  catch (e){
    
    setPropsAlerta(
      utils.montaAlerta({
        titulo: e.message,
        origem: e.origem,
        tAnimacao: e.tipoAlerta,
      })
    );

  }
} 



function onSubmit(errors,values, resetForm)  {
  try {  
  
 
   if(countQuestion != values.listAnswer.filter(n => n).length) {
    setPropsAlerta(
      utils.montaAlerta({
        titulo: 'THE COMPLETE THE TESTE, YOU MUST ANSWER ALL THE QUESTIONS',     
        tAnimacao: 2,
      })
    );
    
 
   }else{

  

    FinalizarTeste(values.listAnswer.filter(n => n), resetForm)
   
   }
  } catch (e){

    setPropsAlerta(
      utils.montaAlerta({
        titulo: 'THE COMPLETE THE TESTE, YOU MUST ANSWER ALL THE QUESTIONS',      
        tAnimacao: 2,
      })
    );
  }
  }




    return (
<Divbackground>  
<Modal style={stilo} isOpen={exibirModal} ><Mensagem setExibirModal= {()=> setExibirModal ()} /></Modal>
<Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={propsAlerta.loop}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}         
      />



 {exibeMensagem? 
<Formik


  initialValues={{
  }}



render = {({values, errors}) => (
    <>
<FieldArray
     id="listAnswer"
     render={arrayHelpers => (
<CaixaFundoMessage>     
    <Imagem>          
      <img src="../mila2.png"/>
   </Imagem> 
      <Message>
        {obj.Message}
      </Message>
       
      <Botao type="submit" onClick={() => { nextTest() }}  >
        Next
    </Botao>
 </CaixaFundoMessage>  
     )}
   />

</>
   )}
   />


:

<Formik

initialValues={
{}
}


render = {({values, errors, resetForm}) => (


    <>      
<CaixaFundoQuestion>
<div style={{display: 'flex'}}>    
   <ImagemMila>       
      <img src="../mila2.png"/>
    
   </ImagemMila> 
   {
    <Level Level={obj.Description}/>
    }  
   </div>

   <FieldArray
     name="listAnswer"
     
     render={arrayHelpers => (   
  
      
   <div>
     {      
  obj.ListGroup?.map((x)=> {
   {

   setCountQuestion(x.ListQuestion.filter(ListQuestion => ListQuestion.Type.oEnum !=0 ).length) 

  const question = x.ListQuestion.map((y,x)=>   

  
          <>
          
           
     <Perguntas key={x} Tipo={y.Type.oEnum} Numero={y.Number} Descricao={y.Description}/>
      <Respostas   id={x} respostas={y.ListAnswer} erro={errors} tipo={y.Type.oEnum} />    
  

     
              </>   
    
  
       )  ; 
      
      
       return (     
    
         
        question 
     );
   }  

 } )
 } 
 </div>
 )}
 />

<DivButton >
<Button  type='submit' onClick={() => {onSubmit(errors,values,resetForm)} }  >Finish</Button>
</DivButton>
</CaixaFundoQuestion>   
</>
   )}
   />



}
</Divbackground>
    )   
    

 
}

