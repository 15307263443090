import React from 'react';
import { DivRadio, RadioField,DivRadioType2, SpanRadioInput} from './inputRadio';
import {Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { Input } from 'style-components';
import { Span } from '../Pages/Login/Style';



const Respostas = ({respostas, id, erro, tipo}) => {
 

    return(
<>

{
      respostas?.map((x)=> {     
       
        { 
      return(
             <>        
            <DivRadio style={{display: tipo == 1 ? 'flex' : 'table-cell' }}>
        <RadioField   type="radio"  id={x.Id} name={`listAnswer.${id}.id`}  value={"".concat(x.Id)} />
        <label style= {{color:'black'}}  for={x.Id} >{x.Description}</label><br/>
        </DivRadio>
        </>  
        )

}
})

}
</>
)
}


  export default Respostas;