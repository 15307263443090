import styled from 'styled-components';


export const Button = styled.button `
width: 18%;
height: 40px;
border: none;
outline: none;
color:black;
background: linear-gradient(to right, #ff7505, #ff7505);  
padding: 10px 20px;
font-size: 18px;
cursor: pointer;
border-radius: 15px;
margin-top:60px;
box-shadow: 0 3px 0 rgb(77, 75, 75);
align-items:center;
:hover {
    background: linear-gradient(to right, #ff7605d7, #ff7605d7); 
    color:black;
}

:active {
    position:relative;
    top:5px;
    box-shadow: none;
}
`;

export const DivButton = styled.div `

    display: flex ;
    align-items: center;
    justify-content: right;

    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
`;


