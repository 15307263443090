import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Login/Index';
import Test from './Pages/ListQuestion';
import Disapproved from './Pages/Disapproved';


export default function Routes () {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/Test" component={Test} />
          <Route path="/Finish" component={Disapproved} />
        </Switch>
      </BrowserRouter>
    );
  }
