import * as app from './api';
import * as utils from '../utilitario';

export const salvar = async ({name,email,phone,id})=> {
    try {

        const obj = {
            Name : name,
            Email : email,
            Phone : phone ,
            Id: id
        };

        const ret = await app.salvarTest({dados: obj});      
        return ret;
    } catch (e) {
      throw utils.trataErro(e,"salvar")
    }

}

export const FinishTest = async(values, idLevel, idTest)=> {
    try {
       
        const obj = {
            ListAnswer : values,
            IdLevel : idLevel,
            IdTest : idTest 
        };
        const ret = await app.FinishTestAluno({dados:obj});
                
        return ret;
    } catch (e) {
       
        throw utils.trataErro(e,"FinishTest")
    }
}