import { Modal } from '@material-ui/core';
import styled from 'styled-components';


export const Paragrafo = styled.p `
text-align: justify;
text-indent: 20px;
border-left: 15px solid transparent;
border-right: 15px solid transparent;

`;

export const ModalMila = styled(Modal)`
  & .MuiBackdrop-root {
    background-color: #000000c4;
  }
`;
const fontePadrao = { primaria: "Montserrat", secundaria: "Lato" };
export const Tema = {
  CorFundoApp: "#fdc629",
  CorFundoTela: "#e2e2e2",
  CorSecundaria: "#52658c",
  CorTerciaria: "#52658c",
  CorAdicionar: "#67b652",
  CorFont: "#575756",
  Font: fontePadrao.primaria,
  FontSecundaria: fontePadrao.secundaria,
  CorFundoCampos: "rgba(130, 130, 130, 0.10)",
  corSucesso: "white",  
  corErro: "white",
  corAviso: "white",
  fundoCarregando: {
    backgroundColor: "#F1F1F1",
    top: "25%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    height: "90%",
  },
  fundoProcessando: {
    backgroundColor: "#FFFF",
    top: "9%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    height: "82%",
  },
};

const TextoPadrao = styled.span`
  font-family: ${Tema.Font};
  color: ${Tema.CorFont};
`;
export const TituloAnimacao = styled(TextoPadrao)`
  color: #fff;
  ${(props) => props.corTexto && `color: ${props.corTexto};`}
  text-align: center;
  font-size: 1.8em;
  text-transform: uppercase;
  font-weight: bold;
`;





export const TextoAnimacaoFundo = styled.div`
  width: 100%;
  text-align: center;
`;
export const TextoAnimacao = styled(TextoPadrao)`
  color: ${Tema.CorSecundaria};
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
`;
export const DivFechar = styled(TextoAnimacaoFundo)`
  display: flex;
  margin-right: -2%;
  text-align: left;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const CarregandoFundo = styled.div`
  background-color: transparent;


  margin: 2%;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 80%;
  outline: none;
  border-radius: 20px;
`;

export const Questao = styled.h4 `
text-align: justify;

border-left: 15px solid transparent;
border-right: 15px solid transparent;
color: black;
font-weight:580;
`;
export const AnimacaoFundo = styled.div`
  width: 45%;
`;
export const Titulo = styled.h2 `
text-align:center;
color: black;
font-style: italic;
    color:black;
    font-style: italic  ;
    font-weight:700;
  width: calc(100% - 33%);
    

`;



export const ImagemMila = styled.image `

height: 80px;
width: 120px;
img{    
    width: 120px;
    justify-content: center;
    display: flex ;
    align-items: center;
    justify-content: center;
}


`;






