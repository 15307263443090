import React from 'react';
import * as Tema from './Style';
import { BotaoSeguir } from './Style'; 
import { DivMensagem,TamanhoModal,DivBotao} from './Style';




const Mensagem = ({setExibirModal}) => {

    
function fecharModal() {
 
    setExibirModal(false)

   }
 
        return (
            <>
                <TamanhoModal>
                <DivMensagem >
               
                    <Tema.TextoAnimacao><b>To complete the test, you must answer all the questions!</b></Tema.TextoAnimacao><br />

                  <DivBotao>
                  <BotaoSeguir type="submit" onClick={() => { fecharModal() }}  >                   
                        Ok</BotaoSeguir>
                  </DivBotao>

                </DivMensagem>
                </TamanhoModal>
                


            </>
        )
    }


    export default Mensagem;